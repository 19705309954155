<script setup lang="ts">

</script>

<template>
  <div h-screen>
    <iframe src="https://reurl.cc/1beZ6G" h-full w-full flex-1 />
  <TheFooter fixed bottom-0 h-50 w-full />
</div>
</template>
